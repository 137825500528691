<!--个人中心用户会员过期显示组件-->
<template>
    <div class="info-wrapper">
        <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon_user_083104.png">
        <div class="info-text">{{memberText}}</div>
        <div class="info-time">{{expireTimeText}}</div>
    </div>
</template>

<script>
  import { standardTimeIsOver } from '../../utils/tools'

  export default {
    name: 'UserCardPassTime',
    props: ['cardType', 'expireTime'],
    data () {
        return {
          newCardType: '',
          newExpireTime: '',
          memberText: '普通会员',
          expireTimeText: ''
        }
    },
    mounted () {
      // console.log(this.cardType)
      // console.log(this.expireTime)
    },
    methods: {
      refresh: function () {
        // 过期时间文字
        if (standardTimeIsOver(this.newExpireTime)) {
          this.memberText = '普通会员'
          switch (this.newCardType) {
            case '1':
              this.expireTimeText = '碗卡已过期'
              break
            case '2':
              this.expireTimeText = '月卡已过期'
              break
            default:
              this.expireTimeText = '暂未开通会员'
              break
          }
        } else {
          switch (this.newCardType) {
            case '1':
              this.memberText = '碗卡会员'
              break
            case '2':
              this.memberText = '月卡会员'
              break
            default:
              this.memberText = '普通会员'
              break
          }
          this.expireTimeText = this.newExpireTime + '过期'
        }
      }
    },
    watch: {
      cardType: function (newVal, oldVal) {
        this.newCardType = newVal
        this.refresh()
        // console.log(2)
      },
      expireTime: function (newVal, oldVal) {
        this.newExpireTime = newVal
        this.refresh()
      }
    }
  }
</script>

<style scoped>
    .info-wrapper{
        width: 90%;
        padding: 0.3rem 0.5rem;
        border-radius: 0.2rem;
        background-color: #F7CE75;
        display: flex;
        align-items: center;
        color: #444;
        margin:1vw 3vw 0 3vw;
        font-size: 3vw;
        box-sizing: border-box;
    }
    .info-wrapper img{
        width: 1.2rem;
        margin: 0 1vw;
    }
    .info-text {
        margin-left: 0.3rem;
    }
    .info-time {
        margin-left: 0.5rem;
    }
</style>
