<!--个人中心，对应旧版本的user.html-->
<template>
    <div>
        <!--用户面板-->
        <div class="member-panel-wrapper" :style="{'background-image':cardBackground}">
            <div class="member-info-wrapper">
                <p class="member-name">{{weixinNick}}</p>
                <div class="member-mobile" >{{mobile}}</div>
            </div>
        </div>
        <!--分销情况-->
        <div class="profit-brief-container" v-if="isDistributor">
            <div class="profit-wrapper">
                <div class="profit-money-wrapper" @click="viewMoney">
                    <div>￥<span>{{surplus}}</span></div>
                    <div>可提现</div>
                </div>
                <div class="profit-money-wrapper" @click="viewMoney">
                    <div>￥<span>{{extract}}</span></div>
                    <div>已提现</div>
                </div>
                <div class="profit-money-wrapper" @click="viewMoney">
                    <div>￥<span>{{totalIncome}}</span></div>
                    <div>总收益</div>
                </div>
            </div>
            <user-card-pass-time v-show="isDistributor" :cardType="cardType" :expireTime="expiretime"></user-card-pass-time>
        </div>
        <!--服务入口-->
        <grid-title
                icon="https://seetop-1257860468.file.myqcloud.com/html/img/icon_user_083101.png"
                title="一碗生活家"
                style="margin-left: 1.5rem"
                v-if="isDistributor"
        ></grid-title>
        <div class="grid-wrapper" v-if="isDistributor">
            <router-link class="grid-entrance-wrapper" to="/distribution">
                <div class="grid-entrance-icon" style="background-position: -14.5vw 0"></div>
                <p class="grid-entrance-text">我的生活家</p>
            </router-link>
            <router-link class="grid-entrance-wrapper" to="/distribution/money">
                <div class="grid-entrance-icon" style="background-position: -29.5vw 0"></div>
                <p class="grid-entrance-text">佣金明细</p>
            </router-link>
            <router-link class="grid-entrance-wrapper" to="/distribution/achievement" >
                <div class="grid-entrance-icon" style="background-position: -285vw 0"></div>
                <p class="grid-entrance-text">分销业绩</p>
            </router-link>
            <router-link class="grid-entrance-wrapper" to="/distribution/invite" >
                <div class="grid-entrance-icon" style="background-position: -45vw 0"></div>
                <p class="grid-entrance-text">邀请生活家</p>
            </router-link>
        </div>
        <grid-title
                icon="https://seetop-1257860468.file.myqcloud.com/html/img/icon_user_083102.png"
                title="我的订单"
                style="margin-left: 1.5rem"
                v-if="isDistributor"
        ></grid-title>
        <user-card-pass-time style="margin: -4rem auto 0;" v-show="!isDistributor" :cardType="cardType" :expireTime="expiretime"></user-card-pass-time>
        <div class="grid-wrapper" :style="{ 'margin-top': margintop }">
            <router-link class="grid-entrance-wrapper" to="/cardList" >
                <div class="grid-entrance-icon" style="background-position: -60vw 0"></div>
                <p class="grid-entrance-text">我的碗卡</p>
            </router-link>
            <router-link class="grid-entrance-wrapper" to="/orderList">
                <div class="grid-entrance-icon" style="background-position: -75vw 0"></div>
                <p class="grid-entrance-text">未使用</p>
            </router-link>
            <router-link class="grid-entrance-wrapper" to="/orderList/2">
                <div class="grid-entrance-icon" style="background-position: -90vw 0"></div>
                <p class="grid-entrance-text">已使用</p>
            </router-link>
            <router-link class="grid-entrance-wrapper" to="/hotOrder">
                <div class="grid-entrance-icon" style="background-position: -210vw 0"></div>
                <p class="grid-entrance-text">热门餐厅</p>
            </router-link>
        </div>
        <grid-title
                icon="https://seetop-1257860468.file.myqcloud.com/html/img/icon_user_083103.png"
                title="我的服务"
                style="margin-left: 1.5rem"
        ></grid-title>
        <div class="grid-wrapper">
            <router-link class="grid-entrance-wrapper" to="/commentList">
                <div class="grid-entrance-icon" style="background-position: -120vw 0"></div>
                <p class="grid-entrance-text">我的评论</p>
            </router-link>
            <router-link class="grid-entrance-wrapper" to="/activation">
                <div class="grid-entrance-icon" style="background-position: -135vw 0"></div>
                <p class="grid-entrance-text">激活碗卡</p>
            </router-link>
            <router-link class="grid-entrance-wrapper"  to="/bookingList">
                <div class="grid-entrance-icon" style="background-position: -255vw 0"></div>
                <p class="grid-entrance-text">我的预约</p>
            </router-link>
            <router-link class="grid-entrance-wrapper" to="/couponList">
                <div class="grid-entrance-icon" style="background-position: -150vw 0"></div>
                <p class="grid-entrance-text">优惠券</p>
            </router-link>
            <router-link class="grid-entrance-wrapper" to="/cart">
                <div class="grid-entrance-icon" style="background-position: -270vw 0"></div>
                <p class="grid-entrance-text">购物车</p>
            </router-link>
            <router-link class="grid-entrance-wrapper" to="/addressList">
                <div class="grid-entrance-icon" style="background-position: -165vw 0"></div>
                <p class="grid-entrance-text">收货地址</p>
            </router-link>
            <router-link class="grid-entrance-wrapper" to="/problem">
                <div class="grid-entrance-icon" style="background-position: -180vw 0"></div>
                <p class="grid-entrance-text">常见问题</p>
            </router-link>
            <a class="grid-entrance-wrapper" @click="showCustomerServicePop">
                <div class="grid-entrance-icon" style="background-position: -195vw 0"></div>
                <p class="grid-entrance-text">联系小碗</p>
            </a>
            <a class="grid-entrance-wrapper2"  href="https://club.seetop.net.cn/web/lh/equity.html" v-if="cardnum >= 61101 && cardnum <= 71101">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon20210226.png">
                <p class="grid-entrance-text">龙湖权益</p>
            </a>
        </div>
        <customer-service ref="customerService"></customer-service>
        <page-footer></page-footer>
        <nav-bar></nav-bar>
    </div>
</template>

<script>
  import NavBar from '../../components/common/NavBar'
  import PageFooter from '../../components/common/PageFooter'
  import GridTitle from '../../components/user/GridTitle'
  import { moneyFen2Yuan } from '../../utils/tools'
  import UserCardPassTime from '../../components/user/UserCardPassTime'
  import { globalConfig } from '../../utils/mixin'
  import CustomerService from '../../components/common/CustomerService'
  export default {
    name: 'User',
    mixins: [globalConfig],
    components: { CustomerService, UserCardPassTime, GridTitle, PageFooter, NavBar },
    data () {
      return {
        //  css参数
        margintop: '2vw',
        //  卡面背景 css参数
        cardBackground: 'url("https://seetop-1257860468.file.myqcloud.com/html/img/user_banner_nc.png")',
        isCardUser: false,
        isDistributor: true,
        weixinNick: '一碗福州会员',
        cardnum: '',
        mobile: '1860*******',
        expiretime: '',
        extract: 0,
        surplus: 0,
        totalIncome: 0,
        cardType: '0'
      }
    },
    methods: {
      getUserInfo: function () {
        this.axios.get(this.apiUrl + 'mall/init/user').then((response) => {
          const data = response.data
          if (data.isBindUser) {
            this.isCardUser = data.isCardUser
            this.isDistributor = data.isDistributor
            this.weixinNick = data.info.weixinNick
            this.mobile = data.info.mobile
            this.expiretime = data.info.expiretime
            this.cardnum = data.info.cardnum
            this.cardType = data.info.type
            this.extract = moneyFen2Yuan(data.distributionInfo.extract)
            this.totalIncome = moneyFen2Yuan(data.distributionInfo.extract + data.distributionInfo.surplus + data.distributionInfo.frozen)
            this.surplus = moneyFen2Yuan(data.distributionInfo.surplus)
            // 分销 样式参数设置
            if (!this.isDistributor) {
              this.margintop = '-0.2rem'
            }
            // 龙湖 样式参数设置
            console.log(this.cardnum)
            if (this.cardnum >= '00061101' && this.cardnum <= '00071101') {
              this.cardBackground = 'url("https://seetop-1257860468.file.myqcloud.com/html/img/banner2021022101.jpg")'
            }
          } else {
            // 没有绑定，直接跳转到绑定页面
            this.$router.replace('/bind')
          }
        })
      },
      // 展示客服面板
      showCustomerServicePop: function () {
        this.$refs.customerService.showPop()
      },
      viewMoney: function () {
        this.$router.push('/distribution/money')
      }
    },
    created () {
      this.getUserInfo()
    },
    mounted () {
    },
    computed: {
    }
  }
</script>

<style scoped>
    .member-panel-wrapper{
        /*background: url("https://seetop-1257860468.file.myqcloud.com/html/img/user_banner_nc.png") no-repeat ;*/
        background-size: 100% ;
        width: 100vw;
        height: 42vw;
        padding: 4vw 7vw;
        display: flex;
        box-sizing: border-box;
    }
    .member-info-wrapper{
        color: #fff;
        font-size: 3.8vw;
    }
    .member-name{
        font-size: 4.8vw;
        font-weight: 700;
    }
    .member-mobile{
        margin-top: 1vw;
        font-weight: 500;
    }
    .profit-brief-container{
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        font-size: 2.8vw;
        width: 92vw;
        margin: -18vw auto 4vw auto;
        border-radius: 3vw;
        background-color: #ffffff;
        flex-flow: wrap;
        padding: 4vw 1.5vw;
        box-shadow: none;
        border-bottom: 0.1rem solid #eeeeee;
    }
    .profit-wrapper{
        width: 100%;
        display: flex;
        font-size: 3.8vw;
        justify-content: space-around;
    }
    .profit-money-wrapper{
        color: #DAA759;
    }
    .profit-money-wrapper div{
        margin: 1vw 0;
        width: 25vw;
        text-align: center;
    }
    .profit-money-wrapper div:nth-of-type(1) span{
        font-size: 4.8vw;
        font-weight: 600;
    }
    .profit-money-wrapper div:nth-of-type(2){
        font-size: 3.8vw;
    }
    /*.profit-card-info-wrapper{*/
    /*    width: 90%;*/
    /*    padding: 0.3rem 0.5rem;*/
    /*    border-radius: 0.5rem;*/
    /*    background-color: #F7CE75;*/
    /*    display: flex;*/
    /*    align-items: center;*/
    /*    color: #444;*/
    /*    margin:1vw 3vw 0 3vw;*/
    /*    font-size: 3vw;*/
    /*    box-sizing: border-box;*/
    /*}*/
    /*.profit-card-info-wrapper img{*/
    /*    width: 1.2rem;*/
    /*    margin: 0 1vw;*/
    /*}*/
    /*.profit-card-info-wrapper * {*/
    /*    margin-left: 0.3rem;*/
    /*}*/
    .grid-wrapper{
        width: 92vw;
        margin: 2vw auto 4vw auto;
        border-radius: 3vw;
        background-color: #ffffff;
        display: flex;
        flex-flow: wrap;
        box-shadow: 0 0 8px #e8e8e8;
    }
    .grid-entrance-wrapper{
        width: 25%;
        padding: 2vw 0;
        display: flex;
        display: -webkit-flex;
        flex-flow: column;
        -webkit-flex-flow: column;
        align-items: center;
        -webkit-align-items: center;
    }
    .grid-entrance-icon {
        border-radius: 1.5vw;
        width: 15vw;
        height: 9vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/banner20200129001.png") no-repeat;
        background-size: 300vw;
    }
    .grid-entrance-text {
        font-size: 3vw;
        text-align: center;
        color: #666;
        margin-top: 1vw;
    }
    .grid-entrance-wrapper2{
        width: 25%;
        padding: 1rem 0;
        display: flex;
        display: -webkit-flex;
        flex-flow: column;
        -webkit-flex-flow: column;
        align-items: center;
        -webkit-align-items: center;
    }
    .grid-entrance-wrapper2 img {
        width: 7.2vw;
    }
</style>
