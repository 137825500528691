<!--    常用标题组件，用在个人中心和分销中心  -->
<template>
    <div class="grid-title-wrapper">
        <img class="grid-title-icon" :src="icon">
        <p class="grid-title-text"> {{title}}</p>
    </div>
</template>

<script>
  export default {
    name: 'GridTitle',
    props: ['icon', 'title']
  }
</script>

<style scoped>
    .grid-title-wrapper{
        display: flex;
        flex-flow: row;
        align-items: center;
        width: 10rem;
    }
    .grid-title-icon{
        width: 2rem;
    }
    .grid-title-text{
        font-size: 1rem;
        font-weight: 700;
        color: #333333;
        margin-left: 0.5rem;
    }
</style>
