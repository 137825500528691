<template>
    <div>
        <van-divider>福州视通传媒有限公司提供技术支持</van-divider>
<!--        <div class="space"></div>-->
    </div>
</template>

<script>
  export default {
    name: 'PageFooter'
  }
</script>

<style scoped>
    .space {
        height: 4rem;
        width: 100%;
    }
</style>
